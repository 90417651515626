import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
} from "@mui/material";
import { Text } from "../../UI/Typography";
import Flex from "styled-flex-component";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DocumentTypeChip from "../../UI/DocumentTypeChip/DocumentTypeChip";

interface Props {
  pageGroup: any;
  exportIds: string[];
  setExportIds: (ids: string[]) => void;
}

export default function ExportTableItem({
  pageGroup,
  exportIds,
  setExportIds,
}: Props) {
  const updateIds = (isChecked) => {
    let updatedIds = exportIds.filter((id) => id != pageGroup.doc_id);
    if (isChecked) updatedIds = exportIds.concat(pageGroup.doc_id);
    setExportIds(updatedIds);
  };
  return (
    <>
      <Accordion elevation={0} key={pageGroup.doc_id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ height: "60px", paddingLeft: 0, paddingRight: 0 }}
        >
          <Flex style={{ gap: "20px" }} alignCenter>
            <Checkbox
              checked={exportIds.includes(pageGroup.doc_id)}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                e.stopPropagation();
                updateIds(e.target.checked);
              }}
            />
            <Text customFontSize={1} style={{ width: "22.5vw" }}>
              {pageGroup.doc_id}
            </Text>
          </Flex>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          {pageGroup.pages.map((page, index) => (
            <Flex
              alignCenter
              style={{
                gap: "20px",
                padding: "10px",
                background: index % 2 == 0 ? "whitesmoke" : "white",
                height: "50px",
              }}
              key={index}
            >
              <Text
                customFontSize={1}
                style={{ width: "100px", marginLeft: "60px" }}
              >
                Page {page.page_index}
              </Text>
              <div style={{ width: "400px" }}>
                <DocumentTypeChip
                  label={`${page.code} - ${page.name}`}
                  isPrimary={true}
                  maxWidth={"600px"}
                />
              </div>
              <Text>{page.score}</Text>
            </Flex>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
