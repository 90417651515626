import Flex from "styled-flex-component";
import { Text } from "../../UI/Typography";
import Container from "../../UI/Common/Container";
import { isEmpty } from "lodash";
import { Theme } from "../../../theme";
import { SelectAllOutlined } from "@mui/icons-material";
import { Button, CircularProgress, Input, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  GetInsightsContext,
  GetInsightsContextType,
} from "../../../pages/GetInsights/context/getInsightsContext";
import ClausesListItem from "./ClausesListItem";
import TextMatchesNavigation from "./TextMatchesNavigation";
import { MeliorTranslate } from "../../MeliorTranslate";
import {
  saveDocumentInsight,
  updateDocumentInsight,
} from "../../../api/annotate.api";
import { toast } from "react-toastify";
import { submit } from "../../../api/grab.api";
import DeleteButton from "./DeleteButton";
interface ICLausesListProps {
  refetchInsights: () => void;
}
const ClausesList = ({ refetchInsights }: ICLausesListProps) => {
  const {
    selectedInsight,
    selectedInsightId,
    editSelectedClause,
    selectedRegion,
    setSelectedRegion,
    setEditSelectedClause,
    setSelectedInsight,
    setSelectedInsightId,
    setTextMatchIndex,
    packetData,
    insights,
    loadingInsights,
  } = useContext(GetInsightsContext) as GetInsightsContextType;
  const [hoveredInsight, setHoveredInsight] = useState<string>();
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const isEditing = (key) =>
    Boolean(editSelectedClause && editSelectedClause.clause !== key);
  const [displayedClauses, setDisplayedClauses] = useState<Array<any>>([]);
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const [internalDoneAnnotated, setInternalDoneAnnotated] = useState<any>([]);
  const [newInsight, setNewInsight] = useState<any>({});

  useEffect(() => {
    if (insights) {
      const clausesWithId = attachClauseId(insights, 0);
      setInternalDoneAnnotated(clausesWithId);
      setDisplayedClauses(clausesWithId);
    }
  }, [insights]);

  const getBboxesValue = (): number[][] => {
    const arr: number[][] = [];
    if (!selectedRegion?.bboxes.length) {
      return [];
    }

    selectedRegion.bboxes.map((bbox) => {
      arr.push([bbox.left, bbox.top, bbox.width, bbox.height]);
    });

    return arr as number[][];
  };

  const attachClauseId = (clauses, startIndex) => {
    if (!clauses) return [];
    const clausesWithId: Array<any> = [];

    clauses.forEach((item, i) => {
      clausesWithId.push({
        id: (startIndex + i + 1).toString(),
        ...item,
      });
      return;
    });
    return clausesWithId;
  };

  async function confirm() {
    setIsConfirming(true);
    try {
      updateClauseValues(selectedRegion?.text ?? "", getBboxesValue(), false);
    } catch (e) {
      console.error(e);
      setIsConfirming(false);
    }
  }

  async function reset() {
    setSelectedInsight("");
    setSelectedInsight("");
    setIsResetting(false);
    setIsConfirming(false);
    setEditSelectedClause(undefined);
    setSelectedRegion(undefined);
  }

  const updateClauseValues = (answer, bboxes, notFound) => {
    try {
      updateDocumentInsight(packetData.doc_id, selectedInsightId, {
        clause_name: selectedInsight,
        text: answer,
        bbox: {
          left: bboxes[0][0],
          top: bboxes[0][1],
          width: bboxes[0][2],
          height: bboxes[0][3],
        },
        page_index: notFound ? 0 : selectedRegion?.page ?? 0,
      }).then(() => {
        refetchInsights();
        submit(
          packetData.assignment_id,
          packetData.status == "classified"
            ? "classified_annotated"
            : "annotated"
        );

        setEditSelectedClause(undefined);
        setSelectedRegion(undefined);
        setIsResetting(false);
        setIsConfirming(false);
        setSelectedInsight("");
        setSelectedInsightId("");
        toast.success(
          selectedInsight +
            (!notFound ? " updated successfully." : " marked as not found")
        );
      });
    } catch (e: any) {
      toast.error(e?.detail ?? "Something went wrong");
      setIsConfirming(false);
    }
  };

  const notFound = () => {
    setIsConfirming(true);
    try {
      updateClauseValues("", [[0, 0, 0, 0]], true);
    } catch (e) {
      console.error(e);
      setIsConfirming(false);
    }
  };

  const editSelection = (e, clause) => {
    e.stopPropagation();
    setEditSelectedClause({
      clause: clause.clause_name,
      text: clause.text,
    });
    setSelectedRegion(undefined);
  };

  const addNewRow = () => {
    const empty = [
      {
        insight_id: "new",
        created_at: "",
        updated_at: "",
        clause_id: "",
        text: "",
        bbox: {
          top: 0,
          left: 0,
          width: 0,
          height: 0,
        },
        document_id: "",
        user_id: null,
        clause_name: "",
      },
    ];
    setNewInsight(empty[0]);
    setDisplayedClauses(empty.concat(displayedClauses));
    setSelectedInsight("");
    setEditSelectedClause({
      clause: "",
      text: "",
    });
  };

  const createNew = () => {
    try {
      setIsConfirming(true);
      const bboxes = getBboxesValue();
      saveDocumentInsight(packetData.doc_id, {
        clause_name: newInsight.clause_name,
        text: selectedRegion?.text ?? "",
        bbox: {
          left: bboxes[0][0],
          top: bboxes[0][1],
          width: bboxes[0][2],
          height: bboxes[0][3],
        },
        page_index: selectedRegion?.page ?? 0,
      }).then((res) => {
        if (res) {
          const updatedlist = [res].concat(
            displayedClauses.filter((clause) => clause.insight_id != "new")
          );
          setDisplayedClauses(updatedlist);

          setEditSelectedClause(undefined);
          setSelectedRegion(undefined);
          setIsResetting(false);
          setIsConfirming(false);
          setSelectedInsight("temp_app");
          setSelectedInsightId("");

          refetchInsights();
          toast.success("Successfully created a new insight");
          setIsConfirming(false);
        }
      });
    } catch (e: any) {
      toast.error(e?.detail ?? "Something went wrong");
      setIsConfirming(false);
    }
  };

  return (
    <div
      style={{
        overflowY: "scroll",
        height: "100%",
        marginTop: "15px",
      }}
    >
      <Flex justifyEnd alignCenter style={{ height: "50px" }}>
        <Button
          onClick={() => addNewRow()}
          disabled={
            displayedClauses.find((clause) => clause.insight_id == "new") ||
            loadingInsights
          }
        >
          Add New Selection
        </Button>
      </Flex>
      {isEmpty(insights) && (
        <Flex
          justifyCenter
          style={{
            marginTop: "20px",
          }}
        >
          {!loadingInsights ? (
            <>No extractions found.</>
          ) : (
            <CircularProgress
              style={{
                color: "#3f51b5",
                width: "30px",
                height: "30px",
              }}
            />
          )}
        </Flex>
      )}
      {displayedClauses.map((clause, index) => (
        <Container key={clause.clause_name}>
          {selectedInsight !== clause.clause_name && (
            <div>
              <ClausesListItem
                index={index}
                clause={clause}
                hoveredInsight={hoveredInsight as string}
                setHoveredInsight={setHoveredInsight}
                isEditing={isEditing}
                setSelectedInsight={setSelectedInsight}
                setSelectedInsightId={setSelectedInsightId}
                setTextMatchIndex={setTextMatchIndex}
                isHideable={true}
                editSelection={editSelection}
                isAnnotated={internalDoneAnnotated.includes(clause.clause_name)}
              />
            </div>
          )}
          {selectedInsight === clause.clause_name && (
            <Container
              borderLeft={`4px solid ${Theme.primary}`}
              borderBottom="1px solid rgba(13, 24, 50, 0.12)"
              leftInnerSpacing={2}
              topInnerSpacing={2}
              bottomInnerSpacing={1.5}
              rightInnerSpacing={1.625}
              onClick={() => {
                setTextMatchIndex(0);
                setSelectedInsight("");
              }}
              backgroundColor="white"
            >
              {clause.insight_id != "new" ? (
                <>
                  <Flex alignCenter justifyBetween>
                    <Text customFontWeight={600} color={Theme.primaryDark}>
                      {clause.clause_name}
                    </Text>
                    <DeleteButton refetchInsights={refetchInsights} />
                  </Flex>

                  <Container
                    topOuterSpacing={1.375}
                    backgroundColor={Theme.background}
                    leftInnerSpacing={0.75}
                    topInnerSpacing={1}
                    bottomInnerSpacing={1}
                    rightInnerSpacing={0.75}
                    minHeight={6.09375}
                    wordBreak
                  >
                    {clause.text == "" && clause.bbox.width == 0 && (
                      <i style={{ opacity: 0.4 }}>empty</i>
                    )}
                    {!isEmpty(clause.clause_name) && (
                      <Flex
                        column
                        justifyBetween
                        style={{ minHeight: "6.09375rem" }}
                      >
                        {
                          <Text customFontSize={0.8125} customFontWeight={400}>
                            {selectedRegion ? selectedRegion.text : clause.text}
                          </Text>
                        }
                        {/* TODO: implement multiple clause values */}
                        {false && (
                          <TextMatchesNavigation items={clause.clause_name} />
                        )}
                      </Flex>
                    )}
                  </Container>

                  {!Boolean(editSelectedClause) && (
                    <Container topOuterSpacing={1} textAlign="right">
                      <Flex full justifyEnd style={{ gap: "10px" }}>
                        {!(clause.text == "" && clause.bbox.width == 0) && (
                          <Tooltip title="Mark as not found">
                            <Button
                              variant="outlined"
                              onClick={() => notFound()}
                            >
                              Not Found
                            </Button>
                          </Tooltip>
                        )}

                        <Tooltip title="Edit definition">
                          <Button
                            variant="outlined"
                            color="success"
                            onClick={(e) => editSelection(e, clause)}
                          >
                            <Flex alignCenter>
                              <SelectAllOutlined />
                              <Container leftOuterSpacing={0.6875}>
                                <MeliorTranslate valueKey="Edit" />
                              </Container>
                            </Flex>
                          </Button>
                        </Tooltip>
                      </Flex>
                    </Container>
                  )}

                  {Boolean(editSelectedClause) && (
                    <Container topOuterSpacing={1} textAlign="right">
                      <Flex full justifyEnd>
                        <Button
                          color="primary"
                          sx={{ marginRight: 1 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            reset();
                          }}
                          disabled={isResetting}
                        >
                          <MeliorTranslate valueKey="Cancel" />
                        </Button>
                        <Button
                          variant="outlined"
                          color="success"
                          disabled={isConfirming || !selectedRegion}
                          onClick={(e) => {
                            e.stopPropagation();
                            confirm();
                          }}
                        >
                          {isConfirming ? (
                            <CircularProgress
                              style={{
                                color: "#3f51b5",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          ) : (
                            <MeliorTranslate valueKey="Confirm" />
                          )}
                        </Button>
                      </Flex>
                    </Container>
                  )}
                </>
              ) : (
                <>
                  {/* Input name */}
                  <Flex alignCenter justifyBetween>
                    <Input
                      style={{ fontSize: "16px" }}
                      disableUnderline={true}
                      placeholder="Enter an alias"
                      type="text"
                      value={newInsight.clause_name}
                      onChange={(e) => {
                        const updatedNew = {
                          ...newInsight,
                          clause_name: e.target.value,
                        };
                        setNewInsight(updatedNew);
                      }}
                    />
                  </Flex>
                  {/* Display answer */}
                  <Container
                    topOuterSpacing={1.375}
                    backgroundColor={Theme.background}
                    leftInnerSpacing={0.75}
                    topInnerSpacing={1}
                    bottomInnerSpacing={1}
                    rightInnerSpacing={0.75}
                    minHeight={6.09375}
                    wordBreak
                  >
                    {
                      <Flex
                        column
                        justifyBetween
                        style={{ minHeight: "6.09375rem" }}
                      >
                        {
                          <Text
                            customFontSize={0.8125}
                            customFontWeight={400}
                            color={selectedRegion ? "black" : "gray"}
                          >
                            {selectedRegion
                              ? selectedRegion.text
                              : "Select text within the document"}
                          </Text>
                        }
                      </Flex>
                    }
                  </Container>
                  {/* Discard & Save */}
                  {
                    <Container topOuterSpacing={1} textAlign="right">
                      <Flex full justifyEnd>
                        <Button
                          color="primary"
                          sx={{ marginRight: 1 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDisplayedClauses(
                              displayedClauses.filter(
                                (clause) => clause.insight_id != "new"
                              )
                            );
                            reset();
                          }}
                          disabled={isResetting}
                        >
                          <MeliorTranslate valueKey="Discard" />
                        </Button>
                        <Button
                          variant="outlined"
                          color="success"
                          disabled={
                            isConfirming ||
                            !selectedRegion ||
                            !newInsight.clause_name
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            createNew();
                          }}
                        >
                          {isConfirming ? (
                            <CircularProgress
                              style={{
                                color: "#3f51b5",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          ) : (
                            <MeliorTranslate valueKey="Save" />
                          )}
                        </Button>
                      </Flex>
                    </Container>
                  }
                </>
              )}
            </Container>
          )}
        </Container>
      ))}
    </div>
  );
};

export default ClausesList;
