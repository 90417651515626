import Flex from "styled-flex-component";
import { Text } from "../../UI/Typography";
import Container from "../../UI/Common/Container";
import { Theme } from "../../../theme";
import { ISelectedEntity } from "../helpers/types";

interface ICLausesListItemProps {
  index: number;
  clause: any;
  hoveredInsight: string;
  setHoveredInsight: (string) => void;
  isEditing: (string) => boolean;
  setSelectedInsight: (insight: string | ISelectedEntity | undefined) => void;
  setSelectedInsightId: (insight: string) => void;
  setTextMatchIndex: (index: number) => void;
  isHideable: boolean;
  editSelection: (e: any, clause: any) => void;
  isAnnotated: boolean;
}

const ClausesListItem = ({
  clause,
  hoveredInsight,
  setHoveredInsight,
  isEditing,
  setSelectedInsight,
  setSelectedInsightId,
  setTextMatchIndex,
  isHideable,
}: ICLausesListItemProps) => {
  const isFresh = clause.created_by == null && clause.updated_by == null;
  const isNew = clause.created_by?.id == clause.updated_by?.id;
  const isNotFound = clause.text == "" && clause.bbox.width == 0;
  return (
    <Container
      onClick={() => {
        setTextMatchIndex(0);
        setSelectedInsight(clause.clause_name);
        setSelectedInsightId(clause.insight_id);
      }}
      pointerEvents={isEditing(clause.clause_name) && isHideable ? "none" : ""}
      backgroundColor={
        isEditing(clause.clause_name) && isHideable
          ? "rgba(13, 24, 50, 0.3)"
          : hoveredInsight == clause.clause_name
            ? "whitesmoke"
            : "white"
      }
      display="flex"
      data-testid="clause-row"
      cursor="pointer"
      borderBottom="1px solid rgba(13, 24, 50, 0.12)"
      leftInnerSpacing={2}
      rightInnerSpacing={1}
      height={5}
      onMouseEnter={() => {
        setHoveredInsight(clause.clause_name);
      }}
      onMouseLeave={() => {
        setHoveredInsight("");
      }}
    >
      <Flex justifyBetween alignCenter style={{ width: "100%" }}>
        <Flex justifyCenter column style={{ gap: "4px" }}>
          <Flex style={{ gap: "5px" }} alignStart>
            <Text
              color={Theme.primaryDark}
              dangerouslySetInnerHTML={{ __html: clause.clause_name }}
            />

            {!isFresh && (
              <Text
                customFontSize={0.7}
                style={{
                  background:
                    isEditing(clause.clause_name) && isHideable
                      ? "gray"
                      : isNotFound
                        ? "gray"
                        : isNew
                          ? "green"
                          : "orange",
                  width: isNotFound ? "70px" : "45px",
                  textAlign: "center",
                  padding: "2px",
                  borderRadius: "10px",
                }}
                color="white"
              >
                {isNotFound ? "Not Found" : isNew ? "New" : "Edited"}
              </Text>
            )}
          </Flex>
          {!isFresh && !isNotFound && (
            <Flex>
              <Text customFontSize={0.65} color="gray">
                {isNew ? "Added by " : "Edited by "}
                {clause.updated_by.email}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Container>
  );
};

export default ClausesListItem;
