import CardContainer from "../../components/UI/Common/Card/Card";
import Container from "../../components/UI/Common/Container";
import DocumentsTable from "../../components/Documents/Tables/Documents";
import { Badge, Box, Button, CircularProgress, Tab, Tabs } from "@mui/material";
import Flex from "styled-flex-component";
import { MeliorTranslate } from "../../components/MeliorTranslate";
import { MeliorTable } from "../../components/MeliorTable";
import { useEffect, useState } from "react";
import {
  createPage,
  getAssigned,
  getUnassignedDocuments,
  getUnassignedPages,
} from "../../api/files.api";
import { grabDocument, grabPage, unassignTask } from "../../api/grab.api";

const DocumentsPage = () => {
  const [listType, setListType] = useState<string>("Assigned to you");
  const [backlogType, setBacklogType] = useState<string>("Documents");
  const [assignedTotal, setAssignedTotal] = useState<number>(0);
  const [assignedList, setAssignedList] = useState<any[]>([]);
  const [backlogs, setBacklogs] = useState<any[]>([]);
  const [backlogsPages, setBacklogsPages] = useState<any[]>([]);
  const [isLoadingBacklogs, setIsLoadingBacklogs] = useState(false);
  const [isLoadingAssigned, setIsLoadingAssigned] = useState(false);

  useEffect(() => {
    refetch();
  }, []);

  const refetch = () => {
    fetchAssigned();
    fetchUnassignedDocs();
    fetchUnassignedPages();
  };

  const fetchUnassignedDocs = () => {
    setIsLoadingBacklogs(true);
    getUnassignedDocuments().then((res) => {
      setBacklogs(res);
      setIsLoadingBacklogs(false);
    });
  };

  const fetchUnassignedPages = () => {
    setIsLoadingBacklogs(true);
    getUnassignedPages().then((res) => {
      setBacklogsPages(res);
      setIsLoadingBacklogs(false);
    });
  };

  const fetchAssigned = () => {
    setIsLoadingAssigned(true);
    getAssigned().then((res: any) => {
      setAssignedTotal(res.total);
      setAssignedList(res.transformed);
      setIsLoadingAssigned(false);
    });
  };

  const grab = (doc_id, page_index, page_id) => {
    if (page_index) {
      if (page_id) {
        doGrabPage(doc_id, page_index, page_id);
      } else {
        createPage(doc_id, page_index).then((res) => {
          const page_id = res.page_id;
          doGrabPage(doc_id, page_index, page_id);
        });
      }
    } else {
      //grab document
      grabDocument(doc_id).then(() => {
        fetchAssigned();
      });
      //remove document from backlog
      setBacklogs(backlogs.filter((backlog) => backlog.doc_id != doc_id));
    }
  };

  const unassign = (assignment_id, parent_assignment_id) => {
    unassignTask(assignment_id).then(async () => {
      fetchUnassignedDocs();
      fetchUnassignedPages();
    });

    if (parent_assignment_id) {
      // find parent doc
      const docIndex = assignedList.findIndex(
        (assigned) => assigned.assignment_id == parent_assignment_id
      );

      if (assignedList[docIndex].pages.length > 1) {
        // create a new list of pages without current page
        const updatedPages = assignedList[docIndex].pages.filter(
          (page) => page.assignment_id != assignment_id
        );

        //update parent doc's pages with new list
        const updatedList = assignedList.concat([]);
        updatedList[docIndex].pages = updatedPages;

        //update assigned list
        setAssignedList(updatedList);
      } else {
        setAssignedList(
          assignedList.filter(
            (assigned) => assigned.assignment_id != parent_assignment_id
          )
        );
      }
    } else {
      setAssignedList(
        assignedList.filter(
          (assigned) => assigned.assignment_id != assignment_id
        )
      );
    }
    setAssignedTotal(assignedTotal - 1);
  };

  const doGrabPage = (doc_id, page_index, page_id) => {
    grabPage(doc_id, page_id).then(() => {
      fetchAssigned();
    });
    // find parent doc
    const docIndex = backlogsPages.findIndex(
      (backlog) => backlog.doc_id == doc_id
    );
    if (backlogsPages[docIndex].pages.length > 1) {
      // create a new list of pages without current page
      const updatedPages = backlogsPages[docIndex].pages.filter(
        (page) => page.page_index != page_index
      );

      //update parent doc's pages with new list
      const updatedBacklogsPages = backlogsPages.concat([]);
      updatedBacklogsPages[docIndex].pages = updatedPages;

      //update backlogs pages
      setBacklogsPages(updatedBacklogsPages);
    } else {
      setBacklogsPages(
        backlogsPages.filter((backlog) => backlog.doc_id != doc_id)
      );
    }
  };

  return (
    <Container outerSpacing={1}>
      <CardContainer>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Flex justifyBetween alignCenter>
            <Tabs value={0} style={{ overflow: "visible" }}>
              <Tab
                label={
                  <Flex alignCenter>
                    <span>
                      <MeliorTranslate valueKey="Packet" />
                    </span>
                    <Container leftOuterSpacing={1}>
                      <Badge
                        badgeContent={
                          listType == "Backlog"
                            ? backlogType == "Documents"
                              ? backlogs.length
                              : backlogsPages.length
                            : assignedTotal
                        }
                        color="primary"
                      />
                    </Container>
                  </Flex>
                }
              />
              <div
                style={{
                  background: "whitesmoke",
                  marginBottom: "10px",
                  borderRadius: "5px",
                  border: "1px solid #0288D1",
                  marginLeft: "30px",
                  visibility: listType == "Backlog" ? "visible" : "hidden",
                }}
              >
                <Button
                  variant={backlogType == "Documents" ? "contained" : "text"}
                  onClick={() => setBacklogType("Documents")}
                  color="info"
                >
                  Documents
                </Button>
                <Button
                  variant={backlogType == "Pages" ? "contained" : "text"}
                  onClick={() => setBacklogType("Pages")}
                  color="info"
                >
                  Pages
                </Button>
              </div>
            </Tabs>
            <Flex alignCenter style={{ gap: "20px", marginBottom: "10px" }}>
              {Boolean(
                listType == "Backlog"
                  ? isLoadingBacklogs && backlogs.length && backlogsPages.length
                  : isLoadingAssigned && assignedList.length
              ) && (
                <CircularProgress
                  style={{
                    color: "#3f51b5",
                    width: "20px",
                    height: "20px",
                  }}
                />
              )}
              <div
                style={{
                  background: "whitesmoke",
                  borderRadius: "5px",
                  border: "1px solid #3874CB",
                }}
              >
                <Button
                  variant={listType == "Backlog" ? "contained" : "text"}
                  onClick={() => setListType("Backlog")}
                >
                  Backlog
                </Button>
                <Button
                  variant={listType == "Assigned to you" ? "contained" : "text"}
                  onClick={() => setListType("Assigned to you")}
                >
                  Assigned to you
                </Button>
              </div>
            </Flex>
          </Flex>
        </Box>
        <MeliorTable
          showLoading={
            listType == "Backlog"
              ? isLoadingBacklogs && !(backlogs.length || backlogsPages.length)
              : isLoadingAssigned && !assignedList.length
          }
          error={""}
          items={
            listType == "Backlog"
              ? backlogType == "Documents"
                ? backlogs
                : backlogsPages
              : assignedList
          }
          tableEl={
            <DocumentsTable
              documents={
                listType == "Backlog"
                  ? backlogType == "Documents"
                    ? backlogs
                    : backlogsPages
                  : assignedList
              }
              refetch={() => refetch()}
              grab={(doc_id, page_id, isExisting) =>
                grab(doc_id, page_id, isExisting)
              }
              unassign={(assignment_id, parent_assignment_id) =>
                unassign(assignment_id, parent_assignment_id)
              }
              isUnassigned={listType == "Backlog"}
            />
          }
          loadingMessage="Loading items"
          emptyMessage={
            listType == "Backlog"
              ? "No items to work on"
              : "No assigned items. Grab from Backlog."
          }
          errorMessage="An error has occurred"
        />
      </CardContainer>
    </Container>
  );
};

export default DocumentsPage;
