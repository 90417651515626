import axios from "axios";
import { getUserToken } from "../utils/userSession";

const instance = axios.create();

const interceptor = async (config) => {
  const token = await getUserToken();
  const backendBaseURL = process.env.REACT_APP_LITH_API_BASE_URL;
  config.baseURL = backendBaseURL ? backendBaseURL : config.baseURL;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
};

instance.interceptors.request.use(interceptor);

export default instance;
