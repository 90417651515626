import { Theme } from "../../../theme";
import Container from "../../UI/Common/Container";
import Flex from "styled-flex-component";
import { Text } from "../../UI/Typography";
import { useContext } from "react";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  FeedbackReviewContext,
  FeedbackReviewContextType,
} from "../../../pages/FeedbackReview/context/feedbackReviewContext";
import DocumentFeedbackReview from "../DocumentFeedbackReview";

interface IFeedbackReviewHeaderProps {
  onSaveSuccess?: () => void;
}

const Header = ({ onSaveSuccess }: IFeedbackReviewHeaderProps) => {
  const { feedback } = useContext(
    FeedbackReviewContext
  ) as FeedbackReviewContextType;

  const navigate = useNavigate();
  const showDocFeedback = !feedback.page_id;

  return (
    <Container
      fixed
      width="100%"
      zIndex={1}
      backgroundColor={Theme.primaryDark}
      height={4.5}
    >
      <Container
        leftInnerSpacing={2}
        rightInnerSpacing={2.6875}
        topInnerSpacing={0.75}
        bottomInnerSpacing={0.75}
        color={Theme.whiteColor}
      >
        <Flex justifyBetween alignCenter>
          <Flex alignCenter style={{ gap: "10px", height: "45px" }}>
            <Container
              rightOuterSpacing={0.75}
              topOuterSpacing={0.4}
              cursor="pointer"
              onClick={() => {
                window.history?.length > 1 ? navigate(-1) : navigate("/");
                localStorage.setItem("currentMatchIndex", "0");
                if (!showDocFeedback) onSaveSuccess && onSaveSuccess();
              }}
            >
              <ChevronLeft style={{ color: Theme.whiteColor }} />
            </Container>
            <Container maxWidth={30}>
              <Text
                customFontWeight={600}
                data-testid="header-document-name"
                customFontSize={1}
                color={Theme.whiteColor}
                whiteSpace="break-spaces"
                wordBreak="break-all"
              >
                {feedback.doc_id}
              </Text>
            </Container>
            {showDocFeedback && <DocumentFeedbackReview />}
          </Flex>
          {/* <Container leftOuterSpacing={0.5}>
            {pageTypeOptions ? (
              <ItemTypeSelector
                onSaveSuccess={() => onSaveSuccess && onSaveSuccess()}
                isSkippable={isSkippable}
              />
            ) : (
              <></>
            )}
          </Container> */}
        </Flex>
      </Container>
    </Container>
  );
};
export default Header;
