import Navbar from "../Navbar";

interface Props {
  children: React.ReactNode;
}

export default function DefaultLayout({ children }: Props) {
  return (
    <>
      <Navbar />
      <div style={{ paddingTop: "75px", width: "100%" }}>{children}</div>
    </>
  );
}
