import { Button } from "@mui/material";
import NavbarStyled from "./Navbar.styled";
import Flex from "styled-flex-component";
import { Text } from "../Typography";
import { supabase } from "../../../utils/supabase";
import { removeUserSessionData } from "../../../utils/userSession";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currPage, setCurrPage] = useState("Tasks");
  const navButtons = [
    {
      text: "Tasks",
      onClick: () => {
        navigate("/");
      },
    },
    {
      text: "Feedback",
      onClick: () => {
        navigate("/feedback");
      },
    },
    {
      text: "Export",
      onClick: () => {
        navigate("/export");
      },
    },
    {
      text: "Log out",
      onClick: () => {
        logout();
      },
    },
  ];

  useEffect(() => {
    if (location.pathname.includes("feedback")) setCurrPage("Feedback");
    else if (location.pathname.includes("export")) setCurrPage("Export");
    else setCurrPage("Tasks");
  }, [location.pathname]);

  const logout = async () => {
    await supabase.auth.signOut();
    removeUserSessionData();
    navigate("/login");
  };

  const navButton = (text, onClick) => {
    return (
      <Button
        style={{
          color: currPage == text ? "#3874CB" : "gray",
          fontWeight: currPage == text ? 700 : 500,
        }}
        onClick={() => {
          onClick();
        }}
      >
        {text}
      </Button>
    );
  };

  return (
    <NavbarStyled>
      <Flex
        justifyBetween
        alignCenter
        style={{ paddingRight: "20px", paddingLeft: "20px" }}
      >
        <Flex alignCenter>
          <Text
            customFontSize={1}
            customletterSpacing={1}
            color="#3874CB"
            customFontWeight={700}
          >
            LITH
          </Text>
        </Flex>
        <Flex style={{ gap: "30px" }}>
          {navButtons.map((navItem) =>
            navButton(navItem.text, navItem.onClick)
          )}
        </Flex>
      </Flex>
    </NavbarStyled>
  );
};

export default Navbar;
