import CardContainer from "../../components/UI/Common/Card/Card";
import Container from "../../components/UI/Common/Container";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Tab,
  Tabs,
} from "@mui/material";
import Flex from "styled-flex-component";
import { MeliorTranslate } from "../../components/MeliorTranslate";
import { MeliorTable } from "../../components/MeliorTable";
import { useEffect, useState } from "react";
import { exportPages, fetchPagesForExport } from "../../api/export.api";
import ExportTable from "../../components/Export/Tables/Table";
import FileSaver from "file-saver";
import { toast } from "react-toastify";

const ExportPage = () => {
  const [pageGroups, setPageGroups] = useState<any[]>([]);
  const [isLoadingPages, setIsLoadingPages] = useState(false);
  const [exportIds, setExportIds] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    refetch();
  }, []);

  const refetch = () => {
    getPagesForExport();
  };

  const getPagesForExport = () => {
    setIsLoadingPages(true);
    fetchPagesForExport().then((res) => {
      setPageGroups(res);
      setIsLoadingPages(false);
    });
  };

  const doExport = () => {
    setIsSaving(true);
    exportPages(exportIds).then((res) => {
      if (res) {
        toast.success("Successfully exported classifications");
        FileSaver.saveAs(res);
        setExportIds([]);
        setSelectAll(false);
        setIsSaving(false);
      }
    });
  };

  const handleSelectChange = () => {
    const checked = !selectAll;
    setExportIds(
      checked ? pageGroups.map((pageGroup) => pageGroup.doc_id) : []
    );
    setSelectAll(checked);
  };

  return (
    <Container outerSpacing={1}>
      <CardContainer>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Flex justifyBetween alignCenter>
            <Tabs value={0} style={{ overflow: "visible" }}>
              <Checkbox
                checked={
                  exportIds.length != 0 && exportIds.length == pageGroups.length
                }
                indeterminate={
                  exportIds.length != 0 && exportIds.length != pageGroups.length
                }
                onClick={() => handleSelectChange()}
              />
              <Tab
                label={
                  <Flex alignCenter>
                    <span>
                      <MeliorTranslate valueKey="Packet" />
                    </span>
                    <Container leftOuterSpacing={1}>
                      <Badge badgeContent={pageGroups.length} color="primary" />
                    </Container>
                  </Flex>
                }
              />
            </Tabs>
            <Flex alignCenter style={{ gap: "20px", marginBottom: "10px" }}>
              <Badge
                badgeContent={isSaving ? 0 : exportIds.length}
                color="warning"
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    doExport();
                  }}
                  disabled={!exportIds.length || isSaving}
                  style={{ width: "140px" }}
                >
                  {isSaving ? (
                    <CircularProgress
                      style={{
                        color: "#3f51b5",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  ) : (
                    "Export"
                  )}
                </Button>
              </Badge>
            </Flex>
          </Flex>
        </Box>
        <MeliorTable
          showLoading={Boolean(isLoadingPages && !pageGroups.length)}
          error={""}
          items={pageGroups}
          tableEl={
            <ExportTable
              pageGroups={pageGroups}
              exportIds={exportIds}
              setExportIds={(ids) => setExportIds(ids)}
            />
          }
          loadingMessage="Loading classifications"
          emptyMessage={"No classifications to export"}
          errorMessage="An error has occurred"
        />
      </CardContainer>
    </Container>
  );
};

export default ExportPage;
