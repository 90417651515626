import { Route, Routes } from "react-router-dom";
import DocumentsPage from "./Documents";
import GetInsightsPage from "./GetInsights";
import LoginPage from "./Login";
import Protected from "../guards/Protected";
import ItemTypeClassificationPage from "./ItemTypeClassification";
import DefaultLayout from "../components/UI/DefaultLayout";
import FeedbacksPage from "./Feedbacks";
import ExportPage from "./Export";
import FeedbackReviewPage from "./FeedbackReview";

const PageRoot = () => {
  return (
    <Routes>
      <Route element={<Protected />}>
        <Route
          path="/"
          element={<DefaultLayout children={<DocumentsPage />} />}
        />
        <Route
          path="/feedback"
          element={<DefaultLayout children={<FeedbacksPage />} />}
        />
        <Route path="/insights" element={<GetInsightsPage />} />
        <Route path="/type" element={<ItemTypeClassificationPage />} />
        <Route path="/feedback-review" element={<FeedbackReviewPage />} />
        <Route
          path="/export"
          element={<DefaultLayout children={<ExportPage />} />}
        />
      </Route>
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
};

export default PageRoot;
