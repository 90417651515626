import { Chip } from "@mui/material";
import Container from "../Common/Container";

interface Props {
  label: string;
  setSelectedType?: (type: string) => void;
  isPrimary?: boolean;
  maxWidth?: string;
}

export default function DocumentTypeChip(props: Props) {
  return (
    <Container>
      <Chip
        label={props.label}
        style={{
          cursor: "default",
          border: props.isPrimary ? "1px dashed green" : "none",
          background: props.isPrimary ? "white" : "whitesmoke",
          maxWidth: props.maxWidth ? props.maxWidth : "200px",
        }}
      />
    </Container>
  );
}
