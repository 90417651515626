import { IHighlight } from "./types";

export function getClauseHighlights(
  insights: any,
  selectedInsight,
  selectedRegion
) {
  if (!insights) return [];

  const highlights: IHighlight[] = [];

  insights.forEach((clause) => {
    highlights.push({
      pageIndex: clause.page_index,
      height: clause.bbox.height,
      left: clause.bbox.left,
      top: clause.bbox.top,
      width: clause.bbox.width,
      text: clause.text,
      name: clause.clause_name,
      isSelected: selectedInsight === clause.clause_name,
    });
  });

  if (selectedRegion) {
    selectedRegion.bboxes.map((bbox) => {
      highlights.push({
        pageIndex: bbox.pageIndex,
        height: bbox.height,
        left: bbox.left,
        top: bbox.top,
        width: bbox.width,
        text: selectedRegion.text,
        isSelected: true,
        isEdited: true,
        name: selectedInsight,
      });
    });
  }

  //scroll to the clause
  const list = window.document.getElementById("clauses-list");
  if (list) {
    const index = insights.findIndex(
      (clause) => clause.clause_name === selectedInsight
    );
    if (index !== -1) {
      list.scrollTo({
        behavior: "smooth",
        top: index * 85,
      });
    }
  }

  return highlights;
}
