import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { TableContainer, TableHead } from "@mui/material";
import { IDocument } from "../../../../@types/Document";
import FeedbacksTableItem from "./Item";
import Flex from "styled-flex-component";

interface IFeedbacksTableProps {
  feedbacks: IDocument[];
  refetch: () => void;
  listType: string;
}

const FeedbacksTable = ({
  feedbacks,
  refetch,
  listType,
}: IFeedbacksTableProps) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <Flex
            alignCenter
            style={{
              height: "60px",
              borderBottom: "1px solid whitesmoke",
              fontWeight: "bold",
            }}
          >
            <div style={{ width: "24vw" }}>Packet Id</div>
            <div style={{ width: "18vw" }}>
              {listType == "Documents" ? "Packet Type" : "Page Number"}
            </div>
            <div style={{ width: "18vw" }}>Suggested Date</div>
            <div style={{ width: "12vw" }}>Suggested By</div>
          </Flex>
        </TableHead>
        <TableBody>
          {feedbacks.map((feedback: any, index) => (
            <FeedbacksTableItem
              key={index}
              feedback={feedback}
              index={index}
              refetch={() => refetch()}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FeedbacksTable;
